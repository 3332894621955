import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import './Chat.scss';
import firebase from "gatsby-plugin-firebase";
import { UserConsumer } from '../../contexts/UserContext';



class Chat extends Component {
  constructor () {
    super();
    this.state = {
      scrollTop: -10,
      message: '',
      messages: []
    };
  }
  
  getAvatar(info) {
    return <div className="p-col-avatar" >
      <Avatar alt={info.name} src={info.photoURL} />
    </div>;
  }

  getMessage(info) {
    return <div className="p-col-message" >
      <div className="p-name" >{ info.me ? 'Yo' : info.name }</div>
      <div className="p-message" dangerouslySetInnerHTML={{__html: info.message }} ></div>
    </div>;
  }

  getContainerMessages(info, key) {
    return <div key={key} className={'p-container-message ' + (info.me ? 'p-me' : info.name)} >
      { info.me ? [this.getMessage(info), this.getAvatar(info)] : [this.getAvatar(info), this.getMessage(info)] }
    </div>;
  }

  getMessages() {
    return this.state.messages.map((data, key) => {
      return this.getContainerMessages(data, key);
    });
  }

  sendMessage = () => {
    if (this.state.message !== '') {
      let db = firebase.firestore();
      let name = (this.user.displayName || (this.user.name + ' ' + this.user.lastname).trim() || this.user.email);

      db.collection(this.props.info.firestore).add({
        photoURL: this.user.photoURL,
        message: this.state.message,
        name: name,
        uid: this.user.uid,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date())
      })
      .then((docRef) => {
        this.setState({
          ...this.state, ...{
            message: ''
          }
        });
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
    }
  }
  
  changeScroll = () => {
    window.setTimeout(() => {
      let chatsElementScroll = document.querySelectorAll('.p-container-messages');
  
      for (let keyChatsElementScroll in chatsElementScroll) {
        if (!isNaN(parseFloat(keyChatsElementScroll)) && isFinite(keyChatsElementScroll)) {
          let chatElementScroll = chatsElementScroll[keyChatsElementScroll];
    
          let scrollActual = chatElementScroll.scrollTop + chatElementScroll.offsetHeight + 300;
          let scrollMaximo = chatElementScroll.firstElementChild.scrollHeight;
    
          if (chatElementScroll._hasScrollInit === undefined || scrollActual >= scrollMaximo) {
            chatElementScroll.scrollTop = 10000;
            chatElementScroll._hasScrollInit = true;
          }
        }
      }
    }, 10);
  }

  componentDidMount = () => {
    let db = firebase.firestore();

    //orderBy('createdAt').limit(100).
    db.collection("messages--comunidad-drupal").orderBy("createdAt").limit(100).onSnapshot((querySnapshot) => {
      console.log('console- chat - querySnapshot', querySnapshot);
      var messages = [];
      querySnapshot.forEach((doc) => {
        let id = doc.id;
        let allData = doc.data();
        let message = [];

        for (let keyData in allData) {
          let data = allData[keyData];
          message[keyData] = data;
        }

        console.log('prende - nombreapellido ', this.user, this.user.name + ' ' + this.user.lastname);
        if (message.message && message.name && message.uid) {
          message.me = false;
          if (message.uid === this.user.uid) {
            message.me = true;
          }
          messages.push(message);
        }
        console.log("console- chat - message: ", message);
      });
      this.changeScroll();

      this.setState({
        ...this.state, ...{
          messages: messages
        }
      });
    });

    /*
    db.collection("messages--comunidad-drupal").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let id = doc.id;
        let value = doc.data();

        console.log("console- chat - V2: ", id + ' - ' + value);
      });
    });
    */
  }

  onChange = (e) => {
    var obj = [];
    obj[e.target.name] = e.target.value
    this.setState({...this.state, ...obj });
  }

  render () {

    return (
      <UserConsumer>
        {
          user => {
            this.user = user;
            if (user.isLogin) {
              return <div className="block chat" >
                <div className="p-container-messages">
                  <div className="p-subcontainer-messages" scrollTop={this.state.scrollTop}>
                    { this.getMessages() }
                  </div>
                </div>
                <div className="p-container-input">
                  <div className="p-container-input-input">
                    <input name="message" value={this.state.message} onChange={this.onChange} onBurn={this.onChange} />
                  </div>
                  <div className="p-container-input-button">
                   <Button
                      variant="contained"
                      color="primary"
                      endIcon={<SendIcon/>}
                      onClick={this.sendMessage}
                    >
                      Enviar
                    </Button>
                  </div>
                </div>
              </div>
            } else {
              return <div className="block chat" >
                <div className="p-container-messages" >
                  <div className="p-subcontainer-messages" scrollTop={this.state.scrollTop}>
                    { this.getMessages() }
                  </div>
                </div>
              </div>;
            }
          }
        }
      </UserConsumer>
    );
  }
}

export default Chat;
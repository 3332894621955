import React, { Component } from 'react';
import './CommunityHeader.scss';
import ImgDrupal from '../../elements/drupalimage/ImgDrupal';

const CommunityHeader = function(props) {
  return <div className="p-community-header" >
    <div className="p-community-hlogo-cont" >
      <div className="p-community-hlogo" >
        <div className="p-community-himage" >
          <ImgDrupal data={{ id: props.data.image.id, alt: props.data.image.alt }} ></ImgDrupal>
        </div>
      </div>
    </div>
    <div className="p-community-htext" >
      <h1 className="p p-community-htitle" >
        {props.data.title}
      </h1>
      <div className="p-community-hsubtitle" >
      {props.data.subtitle}
      </div>
    </div>
  </div>;
}

export default CommunityHeader;
import React, { Component } from 'react';
import './List.scss';
import Paper from '@material-ui/core/Paper';
import ImageElement from '../../../elements/image/ImageElement';
import { Link }  from 'gatsby';
import ImgDrupal from '../../../elements/drupalimage/ImgDrupal';

class List extends Component {
  constructor() {
    super();

    this.state = {
			data: [
				{
					title: '',
					description: ''
				}
			]
		};
  }

  componentDidMount () {

  }

  render () {
		let { data = {}, numCols, type = 'media', info = {} } = this.props;

		if (info.type) {
			type = info.type;
		}
		if (!info.imageType) {
			info.imageType = 'Drupal';
		}

		if (info.columns === undefined) {
			info.columns = 'columns';
		}

		let title = '';
		if (info.title) {
			title = <h2 className="p" > {info.title} </h2>;
		}
		else if (data.title) {
			title = <h2 className="p" > {data.title} </h2>;
		}

		let lists = data;
		if (data.data) {
			lists = data.data;
		}
		
		let ourGlobalClass = '';
		let ourClass = '';
		switch (info.columns) {
			case 'columns':
				ourGlobalClass = 'list-of-cols';
				ourClass = "list col s12 l" + (12/numCols);
				break;
			case 'width-fixed':
				ourClass = "list width-" + (info.width);
				ourGlobalClass = 'width-fixed';
				break;
			default:
				ourClass = "list col s12 l" + (12/numCols);
		}

		let listItems = lists.map((item, index) => {
			//console.log('Render listItems item 01', item);
			let url = '';
			let target = '';
			if (item.link) {
				if (typeof item.link === 'string') {
					url = item.link;
				}
				if (item.link.url) {
					url = item.link.url;
					if (!item.link.target) {
						target = item.link.target;
					}
				}
			}
			//console.log('Render listItems item 02', 'data', data, 'info', info, 'data.image', data.image, 'title', item.title);
			
			var getItemStructure = () => {
				switch(type) {
					case 'items':
						return (<Paper key={index} className="item" elevation={1}>
								<div className="itemcard items">
									<Link className="dc p-image" to={url} >
										{
											(
												info.imageType === 'Drupal' ? <ImgDrupal data={{ id: (item.image ? item.image : ''), relativePath: (item.image_relativepath ? item.image_relativepath  : '')  }} /> : <img src={item.image ? item.image : '' } />
											)
										}
									</Link>
									<div className="content">
										<div className="container">
											<Link className="dc" to={url} >
												<h3 className="p" >{ item.title }</h3>
											</Link>
											<div className="paragraph" dangerouslySetInnerHTML={{__html: item.description }}></div>
										</div>
									</div>
								</div>
							</Paper>);
					case 'media':
						return (<Paper key={index} className={ourClass} elevation={1}>
								<div className="itemcard media">
									<div className="image">
										{
											(
												info.imageType === 'Drupal' ? <ImgDrupal data={{ id: (item.image ? item.image : ''), relativePath: (item.image_relativepath ? item.image_relativepath  : '')  }} /> : <img src={item.image ? item.image : '' } />
											)
										}
									</div>
									<div className="content">
										<h3 className="p" >{ item.title }</h3>
										<div className="paragraph" dangerouslySetInnerHTML={{__html: item.description }}></div>
									</div>
								</div>
							</Paper>);
					case 'card':
						 return (<div key={index} className={ourClass}>
							 <div className="itemcard card">
								 <div className="image">
		 
								 </div>
								 <div className="content">
									 <h3 className="p" >{ item.title }</h3>
									 <div className="paragraph" dangerouslySetInnerHTML={{__html: item.description }}></div>
								 </div>
							 </div>
						 </div>);
				}
				
			}

			if (item.link) {
				if (item.link.url) {
					if (typeof item.link.url == 'string' && item.link.url != '') {
						if (!item.link.target) {
							item.link.target = '';
						}
						return <a target={item.link.target} href={item.link.url} >
							{ getItemStructure() }
						</a>
					}
					else {
						return getItemStructure();
					}
				}
				else {
					if (typeof item.link == 'string' && item.link != '') {
						return <a href={item.link} >
							{ getItemStructure() }
						</a>
					}
					else {
						return getItemStructure();
					}
				}
			}
			else {
				return getItemStructure();
			}
		});

    return (
			<div className={"structure-list " + ourGlobalClass} >
				{title}
				<div className="list" >
					{listItems}
				</div>
      </div>
    );
  }
}

export default List;
import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Layout from "../../layout"
import { filterTextFormat } from '../../../services/rest/connect';
import BuscarTutorialBlock from '../../../components/blocks/buscar/BuscarTutorialBlock'
import ImgDrupal from '../../elements/drupalimage/ImgDrupal';
import Grid from '@material-ui/core/Grid';
import './Community.scss';
import CommunityHeader from '../../blocks/community/CommunityHeader';
import { Link } from 'gatsby';
import Avatar from '@material-ui/core/Avatar';
import Chat from '../../blocks/chat/Chat';

class contentPage extends Component {

  loadDashBoards = () => {
    return this.props.pathContext.field_dashboard.map((val) => {
      //console.log('val', val);
      switch (val.id) {
        case "c03d1460-874d-41ac-ba2c-5ddeb5655959":
          return <Paper className={'p-dashboard-item'}>
            <h2 className="p" >Ultimas preguntas</h2>
            <div className="p-list">
              <Link to="/comunidad-drupal/preguntas/1024" >Tengo problemas con el access en el código del bloque, de vez en cuando se oculta</Link>
              <Link to="/comunidad-drupal/preguntas/1024" >Tengo problemas con el access en el código del bloque, de vez en cuando se oculta</Link>
              <Link to="/comunidad-drupal/preguntas/1024" >Tengo problemas con el access en el código del bloque, de vez en cuando se oculta</Link>
            </div>
          </Paper>;
        case "c736045d-7bb5-4ca1-9eeb-6bc8b3665a2d":
          return <Paper className={'p-dashboard-item'}>
            <h2 className="p" >Mejores Drupalistas</h2>
            <div className="p-grid-avatar">
              <div className="p-container-avatar" >
                <Avatar className="p-avatar" alt="Remy Sharp">J</Avatar>
              </div>
              <div className="p-container-name" >
                Jair Hernandez
              </div>
              <div className="p-container-area" >
                casasas
              </div>
            </div>
            <div className="p-grid-avatar">
              <div className="p-container-avatar" >
                <Avatar className="p-avatar" alt="Remy Sharp">J</Avatar>
              </div>
              <div className="p-container-name" >
                Jair Hernandez
              </div>
              <div className="p-container-area" >
                casasas
              </div>
            </div>
            <div className="p-grid-avatar">
              <div className="p-container-avatar" >
                <Avatar className="p-avatar" alt="Remy Sharp">J</Avatar>
              </div>
              <div className="p-container-name" >
                Jair Hernandez
              </div>
              <div className="p-container-area" >
                casasas
              </div>
            </div>
            
          </Paper>;
        case "b256610a-da20-43cc-a2bd-81ce267c66fa":
          return <Paper className={'p-dashboard-item'}>
            <h2 className="p" >Eventos</h2>
            <div>
              Si quieres que tu sitio sea mucho mas rapido no te puedes perder: 
              <Link to="/comunidad-drupal/evento/cache-drupal/" > Como usar <strong>caches</strong> en Drupal</Link>
            </div>
          </Paper>;
        case "b256610a-da20-43cc-a2bd-81ce267c66fa":
          return <Paper className={'p-dashboard-item'}>
            <h2 className="p" >Chat</h2>
            <Chat></Chat>
          </Paper>;
      }
    });
  };
  
  render() {
    //console.log('Eduint Community', this.props);
    return <Layout>
      <div className="page community" >
        <CommunityHeader data={{
          title: this.props.pathContext.title,
          subtitle: this.props.pathContext.subtitle,
          image: {
            id: this.props.pathContext.field_image_community,
            alt: this.props.pathContext.title,
          }
        }} ></CommunityHeader>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          className="p-dashboard-container" 
        >
          {this.loadDashBoards()}
          <Paper className={'p-dashboard-item p-dashboard-item-2'}>
            <h2 className="p" >Chat</h2>
            <Chat info={{ firestore: 'messages--comunidad-drupal' }} ></Chat>
          </Paper>
        </Grid>
        
        {/*
          <h2 className="p" >{this.props.pageContext.title}</h2>
          <div className="p-text" dangerouslySetInnerHTML={{__html: filterTextFormat(this.props.pageContext.body) }} ></div>
        */}
      </div>
      <BuscarTutorialBlock></BuscarTutorialBlock>
    </Layout>
  }
}

export default contentPage;